import React from "react"
import styled from "styled-components"
import { Link } from "react-scroll"

// Styles
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"
import { fastTransition, fadeIn } from "../styles/animations"

interface IProps {
  className?: string
}

const ScrollHint = ({ className }: IProps) => (
  <Container
    className={className}
    to="projects"
    spy
    smooth
    duration={400}
    offset={-80}
  >
    <Title>My Work</Title>
    <Chevron />
  </Container>
)

const Title = styled.h5<any>`
  ${fadeIn};
  ${textStyles.body};
  margin-bottom: 8px;
  color: ${colors.black};
  font-weight: 600;
  transition: color ${fastTransition}, transform ${fastTransition};

  ${mq.from.M`
    margin-bottom: 8px;
  `};
`

const Chevron = styled.div`
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-12px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  width: 34px;
  height: 20px;
  opacity: 0;
  animation-name: fadeInDown;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  :after,
  :before {
    content: "";
    position: absolute;
    top: 0;
    width: 24px;
    height: 3px;
    background-color: ${colors.black};
    transition: background-color ${fastTransition};
  }

  :before {
    left: 0;
    transform: rotate(45deg);
    transform-origin: left bottom;
  }

  :after {
    left: 10px;
    transform-origin: right bottom;
    transform: rotate(-45deg);
  }
`

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.4;
  transition: opacity ${fastTransition};

  :hover {
    cursor: pointer;
    opacity: 1;

    ${Title} {
      color: ${colors.caribbean};
      transform: scale(1.2);
    }

    ${Chevron} {
      :after,
      :before {
        background-color: ${colors.caribbean};
      }
    }
  }
`

export default ScrollHint
