import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"

// Context
import { AppContext } from "../context/AppContext"

// Components
import SEO from "../components/Seo"
import Header from "../components/Header"
import ContentWrapper from "../components/ContentWrapper"
import ProjectCard from "../components/ProjectCard"
import Footer from "../components/Footer"

// Types
import { IHomepage } from "../types/pages/home"

const HomePage = ({ metaData, header, projectCards, footer }: IHomepage) => {
  const context = useContext(AppContext)

  const scrollTo = () => {
    if (context) {
      if (context.scrollPosition > 0) {
        window.scrollTo(0, context.scrollPosition)
        context.setScrollPosition(0)
      }
    }
  }

  useEffect(() => {
    scrollTo()
  }, [])

  return (
    <>
      <SEO title={metaData.title} description={metaData.description} />
      <Header {...header} />
      <ContentWrapper id="projects">
        {projectCards.map((card, i) => (
          <ProjectCard
            key={`project-${i}`}
            data={{
              date: card.date,
              tags: card.tags,
              title: card.title,
              intro: card.intro,
              image: card.image,
            }}
            url={card.id}
            odd={i % 2 === 0}
          />
        ))}
      </ContentWrapper>
      <Footer {...footer} />
    </>
  )
}

export const query = graphql`
  query homePage {
    prismicHomepage {
      data {
        # Metadata
        meta_title
        meta_description

        # Header
        name {
          text
        }
        role {
          text
        }
        intro {
          html
        }
        small_blob {
          alt
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        large_blob {
          alt
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        resume {
          url
        }

        # Footer
        footer_title {
          text
        }
      }
    }
    # Projects
    allPrismicProject(sort: { fields: data___position }) {
      nodes {
        id
        data {
          date(formatString: "MMMM, YYYY")
          tags {
            tag
          }
          title {
            text
          }
          intro {
            html
          }
          image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
    # Contact
    prismicContact {
      data {
        email_address
        phone_label
        phone_number
        linkedin_label
        linkedin_url
      }
    }
  }
`

export default ({ data }: any) => {
  const rawData = {
    page: {
      ...data.prismicHomepage.data,
    },
    projects: data.allPrismicProject.nodes,
    contact: {
      ...data.prismicContact.data,
    },
  }

  const metaData = {
    title: rawData.page.meta_title,
    description: rawData.page.meta_description,
  }

  const header = {
    name: rawData.page.name.text,
    role: rawData.page.role.text,
    intro: rawData.page.intro.html,
    smallBlob: {
      url: rawData.page.small_blob.fluid,
      alt: rawData.page.small_blob.alt,
    },
    largeBlob: {
      url: rawData.page.large_blob.fluid,
      alt: rawData.page.large_blob.alt,
    },
    resume: rawData.page.resume.url,
  }

  const projectCards = rawData.projects.map((item: any) => {
    const tags = item.data.tags.map((tag: { tag: string }) => tag.tag)
    return {
      id: item.id,
      date: item.data.date,
      tags,
      title: item.data.title.text,
      intro: item.data.intro.html,
      image: {
        url: item.data.image.fluid,
        alt: item.data.image.alt,
      },
    }
  })

  const footer = {
    title: rawData.page.footer_title.text,
    email: rawData.contact.email_address,
    phone: {
      label: rawData.contact.phone_label,
      number: rawData.contact.phone_number,
    },
    linkedin: {
      label: rawData.contact.linkedin_label,
      url: rawData.contact.linkedin_url,
    },
  }

  return (
    <HomePage
      metaData={metaData}
      header={header}
      projectCards={projectCards}
      footer={footer}
    />
  )
}
