import React, { useContext } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import Image from "gatsby-image"

// Context
import { AppContext } from "../context/AppContext"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"

// Components
import Button from "../components/atoms/Button"

// Types
import { IProjectCard } from "../types/components/projectCard"

interface IProps {
  data: IProjectCard
  url: string | undefined
  odd: boolean
}

const ProjectCard = ({ data, url, odd }: IProps) => {
  const context = useContext(AppContext)

  const handleClick = () => {
    if (context) {
      context.setScrollPosition(window.scrollY)
    }
    navigate(`/project/${url}`)
  }

  return (
    <Container reversed={odd}>
      <StyledImage
        fluid={data.image.url}
        alt={data.image.alt || data.title}
        spacing={!odd}
      />
      <Content spacing={odd}>
        {data.date && <Date>{data.date}</Date>}
        {data.tags && (
          <Tags>
            {data.tags.map((tag, i) => (
              <Tag key={`tag-${i}`}>{tag}</Tag>
            ))}
          </Tags>
        )}
        <Title>{data.title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: data.intro }} />
        <Button type="button" onClick={handleClick} label="👀 Read more" />
      </Content>
    </Container>
  )
}

const Container = styled.div<{ reversed: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;

  ${mq.from.L`
    flex-direction: row;
    align-items: center;
    ${({ reversed }: { reversed: boolean }) =>
      reversed && `flex-direction: row-reverse;`}
    margin-bottom: 180px;
  `}
`

const StyledImage = styled(Image)<{ spacing: boolean }>`
  width: 100%;
  margin-bottom: 32px;

  ${mq.from.L`
    min-width: 50%;
    ${({ spacing }: { spacing: boolean | number }) =>
      spacing && `margin-right: 32px;`}
    margin-bottom: 0;

    img {
      margin-bottom: 0;
    }
  `}
`

const Content = styled(({ spacing, ...props }) => <div {...props} />)`
  ${({ spacing }: { spacing: boolean }) => spacing && `margin-right: 32px;`}
`

const Date = styled.span`
  display: block;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 1;
  font-style: italic;
  opacity: 0.4;
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
`

const Tag = styled.span`
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 4px;
  color: ${colors.zuccini};
  background-color: ${colors.caribbean};
  font-size: 16px;
  line-height: 1;
`

const Title = styled.h3`
  ${textStyles.title};
`

const Text = styled.div`
  margin-bottom: 24px;
`

export default ProjectCard
