import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

// Styles
import colors from "../../styles/colors"
import { slowTransition, fastTransition } from "../../styles/animations"

interface IProps {
  type: "button" | "internalLink" | "externalLink"
  label: string
  onClick?: () => void
  to?: string
  target?: "_blank"
  inverted?: boolean
  className?: string
}

const Button = ({
  type,
  label,
  onClick,
  to,
  target,
  inverted,
  className,
}: IProps) => (
  <>
    {type === "button" ? (
      <Btn className={className} onClick={onClick} inverted={inverted ? 1 : 0}>
        {label}
      </Btn>
    ) : type === "internalLink" && to ? (
      <StyledInternalLink
        className={className}
        to={to}
        inverted={inverted ? 1 : 0}
      >
        {label}
      </StyledInternalLink>
    ) : type === "externalLink" && to ? (
      <StyledExternalLink
        className={className}
        href={to}
        target={target}
        inverted={inverted ? 1 : 0}
      >
        {label}
      </StyledExternalLink>
    ) : null}
  </>
)

const buttonStyling = css`
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-right: 18px;
  padding: 8px 24px;
  color: ${colors.zuccini};
  background-color: transparent;
  border: 2px solid ${colors.zuccini};
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  transition: transform ${slowTransition};
  outline: none;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -1;
  }

  &:after {
    background-color: ${({ inverted }: { inverted: number }) =>
      inverted === 1 ? colors.caribbean : colors.white};
    transition: transform ${fastTransition};
    pointer-events: none;
  }

  &:before {
    opacity: 0.5;
    ${({ inverted }: { inverted: number }) =>
      inverted === 1
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 1) 0%,
          rgba(0, 208, 166, 0) 100%
          );`
        : `background: linear-gradient(
          to right,
          rgba(0, 208, 166, 0.6) 0%,
          rgba(0, 208, 166, 0) 100%
          );
    `};
  }

  &:hover:after {
    transform: translateX(100%);
  }
`

const Btn = styled.button`
  ${buttonStyling};
`

const StyledInternalLink = styled(Link)`
  ${buttonStyling};
`

const StyledExternalLink = styled.a`
  ${buttonStyling};
`

export default Button
