import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Image from "gatsby-image"

// Styles
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"
import { fadeIn, fadeInUp } from "../styles/animations"

// Components
import Button from "./atoms/Button"
import ScrollHint from "./ScrollHint"

// Types
import { IHeader } from "../types/components/header"

const Header = ({
  name,
  role,
  intro,
  smallBlob,
  largeBlob,
  resume,
}: IHeader) => {
  const [showScrollHinting, setShowScrollHinting] = useState<boolean>(false)

  const hideScrollHinting = () => {
    if (window.scrollY > 80) {
      setShowScrollHinting(false)
    } else {
      setShowScrollHinting(true)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setShowScrollHinting(true)
    }, 2400)

    window.addEventListener("scroll", hideScrollHinting)

    return () => {
      window.removeEventListener("scroll", hideScrollHinting)
    }
  }, [])

  return (
    <Container>
      <Content>
        <Name animationDelay={800}>{name}</Name>
        <Role animationDelay={1000}>{role}</Role>
        <Intro
          dangerouslySetInnerHTML={{ __html: intro }}
          animationDelay={1200}
        />
        <ButtonsWrapper animationDelay={1600}>
          <Button type="internalLink" to="/about" label="💁🏽‍♀️ About me" />
          <Button
            type="externalLink"
            to={resume}
            target="_blank"
            label="📄 My resume"
          />
        </ButtonsWrapper>
        <SmallBlob
          fluid={smallBlob.url}
          alt={smallBlob.alt}
          animationDelay={600}
        />
        <LargeBlob
          fluid={largeBlob.url}
          alt={largeBlob.alt}
          animationDelay={400}
        />
      </Content>
      {showScrollHinting && <StyledScrollHint />}
    </Container>
  )
}

const Container = styled.header`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 720px;
  padding: 0 24px;
`

const Name = styled.h1<{ animationDelay?: number }>`
  ${fadeInUp};
  z-index: 1;
  ${textStyles.pageTitle};
  line-height: 1;
`

const Role = styled.h2<{ animationDelay?: number }>`
  ${fadeInUp};
  z-index: 1;
  ${textStyles.pageSubtitle};
  color: ${colors.zuccini};
`

const Intro = styled.div<{ animationDelay?: number }>`
  ${fadeInUp};
  z-index: 1;
  margin-bottom: 32px;

  > p {
    ${textStyles.intro};
    margin-bottom: 18px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const ButtonsWrapper = styled.div`
  ${fadeIn};
  display: flex;
`

const SmallBlob = styled(Image)`
  ${fadeIn};
  position: absolute !important;
  top: -35px;
  right: -443px;
  width: 100%;
  margin-bottom: 0;
  transform: scale(0.4);
`

const LargeBlob = styled(Image)`
  ${fadeIn};
  position: absolute !important;
  top: -140px;
  right: -160px;
  width: 100%;
  margin-bottom: 0;
  transform: scale(0.8);
`

const StyledScrollHint = styled(ScrollHint)`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
`

export default Header
